// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();

// Place any jQuery/helper plugins in here.

// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {
	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
		// $('header').toggleClass('menu-active');

		console.log('click burger');
	});

	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			// burger navigation
			$('body').removeClass('bodyfix');
			$('.hamburg er').removeClass('is-active');
			$('header nav').removeClass('is-active');
			nav_reset();

			console.log('esc close menu');
		}
	});
	// ------------------------------------------------------------------

	// headline with dot in view - add classes to animate dot
	// https://stackoverflow.com/questions/41144113/javascript-jquery-add-class-when-element-comes-into-viewport

	function isScrolledIntoView(elem) {
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();

		var elemTop = $(elem).offset().top;
		var elemBottom = elemTop + $(elem).height();

		return elemBottom <= docViewBottom && elemTop >= docViewTop;
	}

	$(window).on('scroll', function() {
		$('article:not(.copy-image) ').each(function() {
			if (isScrolledIntoView($(this))) {
				$(this)
					.find('h2')
					.addClass('in-view');
			} else {
				$(this)
					.find('h2')
					.removeClass('in-view');
			}
		});

		$('article.copy-image .headline-wrapper').each(function() {
			if (isScrolledIntoView($(this))) {
				$(this)
					.find('h2')
					.addClass('in-view');
			} else {
				$(this)
					.find('h2')
					.removeClass('in-view');
			}
		});
	});

	// --------------------------------------------------------------
})(jQuery);
